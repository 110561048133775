import { defineStore } from 'pinia'

export const useCommonStore = defineStore('common', {
  state: () => ({
    token: '',
    userPhone: '', // 当前登录用户手机号
    webConfig: {}
  }),
  getters: {},
  actions: {
    // 网站配置信息
    async fetchWebConfig() {
      const data = await $http('/api/home/visa/web/config', {}, 'get')

      if (data.code === 200) {
        this.webConfig = Object.prototype.toString.call(data.data) === '[object Object]' ? data.data : {}
      }
    },
    logout() {
      return new Promise(resolve => {
        this.token = ''
        this.userPhone = ''

        useNuxtApp().$token.value = null

        resolve()
      })
    }
  }
})
