export const appBaseURL = "/"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1,user-scalable=no,viewport-fit=cover"},{"charset":"utf-8"},{"http-equiv":"Content-Type","content":"text/html;charset=utf-8"},{"http-equiv":"X-UA-Compatible","content":"IE=edge,chrome=1"},{"name":"description","content":"夫为因私出境服务中心"}],"link":[{"rel":"icon","href":"/favicon.ico"}],"style":[],"script":[{"src":"/lib/flexible.js"},{"src":"/lib/_AMapSecurityConfig.js"},{"src":"https://webapi.amap.com/maps?v=2.0&key=8d007b67329f2613d0bf38a015a50cdf&plugin=AMap.ToolBar,AMap.ControlBar"}],"noscript":[{"children":"JavaScript is required"}],"viewport":"width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1,user-scalable=no,viewport-fit=cover","title":"visa"}

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null

export const devRootDir = null