/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(s)
  // return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(s)
  // return /^1[0-9]{10}$/.test(s)
}

/**
 * 手机验证码
 * @param {*} s
 */
export function isSmscode(s) {
  return /^[0-9]{4,6}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
  return /(^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$)|(\d{3}-\d{8}|\d{4}-\d{7})/.test(s)
  // return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 登录名
 * @param s
 * @returns {boolean}
 */
export function isLoginAccount(s) {
  return /[A-Za-z0-9]{6,12}/.test(s)
}

export function isLoginPWD(s) {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,16}$/.test(s)
  // return /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}/.test(s)
}

/**
 * 生日
 * @param s
 * @returns {boolean}
 */
export function isBirth(s) {
  return /^\d{4}-\d{1,2}-\d{1,2}$/.test(s)
}
