export const TokenKey = 'visaWeb_token'
export const UserPhone = 'user_phone'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      token: useCookie(TokenKey, {
        maxAge: 30 * 24 * 3600
      }),
      userPhone: useCookie(UserPhone, {
        maxAge: 30 * 24 * 3600
      })
    }
  }
})
