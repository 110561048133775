import * as formRules from '../utils/rules'
import * as utils from '../utils'

export default defineNuxtPlugin(nuxtApp => {
  Object.keys(formRules).forEach(key => {
    nuxtApp.vueApp.config.globalProperties[key] = formRules[key]
  })

  Object.keys(utils).forEach(key => {
    nuxtApp.vueApp.config.globalProperties[key] = utils[key]
  })
})
