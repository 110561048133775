// 手机号验证
const mobile = (rule, value, callback) => {
  let regFormat = /^1[0-9]{10}$/ //正确手机号
  if (!value) {
    return callback(new Error('不能为空'))
  }
  if (!(regFormat.test(value))) {
    callback(new Error('请输入正确手机号'))
  } else {
    callback()
  }
}
//数字验证
const number = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('不能为空'))
  }
  if (!Number.isInteger(value)) {
    callback(new Error('请输入数字值'))
  } else {
    if (value < 18) {
      callback(new Error('必须大于18岁'))
    } else if (value > 55) {
      callback(new Error('必须小于55岁'))
    } else {
      callback()
    }
  }
}
//text不能为空
const text = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('不能为空'))
  } else {
    callback()
  }
}
//desc不能为空
const desc = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('不能为空'))
  } else {
    callback()
  }
}
//邮箱
const email = (rule, value, callback) => {
  let mal = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  if (!value) {
    return callback(new Error('不能为空'))
  }
  if (!(mal.test(value))) {
    callback(new Error('请输入正确邮箱'))
  } else {
    callback()
  }
}
//开始时间
const startTime = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请选择开始日期'))
  } else {
    callback()
  }
}
//开始时间
const endTime = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请选择结束日期'))
  } else {
    callback()
  }
}
//时间
const time = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请选择日期'))
  } else {
    callback()
  }
}
// 年与日小时分钟秒
const datetime = (rule, value, callback) => {
  if (!value.length) {
    return callback(new Error('请选择日期'))
  } else {
    callback()
  }
}
//多选框
const checkbox = (rule, value, callback) => {
  if (value.length < 1) {
    return callback(new Error('请选择一个'))
  } else {
    callback()
  }
}
//单选框
const radio = (rule, value, callback) => {
  if (value.length < 1) {
    return callback(new Error('请选择一个'))
  } else {
    callback()
  }
}
//下拉框
const select = (rule, value, callback) => {
  if (value && value.length < 1 || value === undefined || value === '') {
    return callback(new Error('请选择一个'))
  } else {
    callback()
  }
}
//QQ号码验证
const QQV = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('输入QQ号'))
  } else if (/^[1-9][0-9]{4,10}$/.test(value)) {
    callback()
  } else {
    callback(new Error('输入正确的QQ号'))
  }
}

// 类似金钱,首位不为0,最多2位小数
function checkNumPot2(rule, value, callback) {
  const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
  if (!value) {
    return callback(new Error('请填写数字'))
  } else if (!reg.test(value)) {
    return callback(new Error('请填写数字,最多2位小数'))
  } else {
    callback()
  }
}

// 身份证
function checkIdNum(rule, value, callback) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  if (!value) {
    return callback(new Error('证件号码不能为空'))
  } else if (!reg.test(value)) {
    return callback(new Error('证件号码不正确'))
  } else {
    callback()
  }
}

// 整数
function checkInterNum(rule, value, callback) {
  const reg = /^[1-9][0-9]*$/
  if (!value) {
    return callback(new Error('请输入整数'))
  } else if (!reg.test(value)) {
    return callback(new Error('请输入整数'))
  } else {
    callback()
  }
}

const confirmPasswordValidator = (rule, value, callback, password) => {
  if (password !== value) {
    callback(new Error('两次密码输入不一致'))
  } else {
    callback()
  }
}

const formRules = {
  QQ: [{ required: true, validator: QQV, trigger: 'blur' }],
  code: [{ required: true, pattern: /^\d{6}$/, message: '手机验证码', trigger: 'blur' }],
  mobile: [{ required: true, pattern: /^1[0-9]{10}$/, message: '目前只支持中国大陆的手机号码', trigger: 'blur' }],
  password: [{ required: true, min: 6, max: 18, message: '长度必须大于6位长度在 6 到 18 个字符之间', trigger: 'blur' }],
  confirmPassword: (form) => {
    return [
      { required: true, min: 6, max: 18, message: '长度必须大于6位长度在 6 到 18 个字符之间', trigger: 'blur' },
      {
        required: true,
        validator: (rule, value, callback) => confirmPasswordValidator(rule, value, callback, form.password),
        trigger: 'blur'
      }
    ]
  },
  required: [{ required: true, min: 1, message: '必填', trigger: 'blur' }],
  numPot2: [{ required: true, validator: checkNumPot2, trigger: 'blur' }],
  InterNum: [{ required: true, validator: checkInterNum, trigger: 'blur' }],
  coins: [{ required: true, validator: checkInterNum, trigger: 'blur' }],
  uploadImgs: [{ type: 'array', required: true, message: '请选择图片', trigger: ['change', 'blur'] }],
  time: [{ required: true, validator: time, trigger: ['change', 'blur'] }],
  datetime: [{ required: true, validator: datetime, trigger: ['change', 'blur'] }],
  select: [{ required: true, validator: select, trigger: 'change' }],
  checkbox: [{ required: true, validator: checkbox, trigger: ['change'] }],
  captchaCode: [
    {
      required: true,
      pattern: /^[0-9a-zA-Z]{4}$/,
      message: '请输入图形验证码',
      trigger: 'blur'
    }
  ]
}


export { formRules }
