import { platform } from '@/utils/platform'
import { customAlphabet } from 'nanoid'
import { isLoginPWD } from '@/utils/validate'

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

export function fen2Yuan(num) {
  return isNaN(num) ? num : (num * 0.01).toFixed(2)
}

export function yuan2Fen(num) {
  if (isNaN(num)) {
    return ''
  }
  const amount = num.toString()
  const index = amount.indexOf('.')
  const arr = amount.split('.')
  let result = arr[0] * 100
  if (index > -1) {
    result += arr[1].split('').reduce((total, cur, index) => {
      if (index === 0) {
        return total + cur * 10
      } else {
        return total + cur * 1
      }
    }, 0)
  }
  return result
}

function suppleZero(num) {
  return num.toString().padStart(2, '0')
}

export function cutDownTimeArr2(ts = 0) {
  let day = Math.floor((ts / 1000 / 3600) / 24)
  let hour = Math.floor((ts / 1000 / 3600) % 24)
  let minute = Math.floor((ts / 1000 / 60) % 60)
  let second = Math.floor(ts / 1000 % 60)
  let milliseconds = (Math.floor(ts % 1000)).toString().replace(/\d{1}$/, '')
  return [suppleZero(day), suppleZero(hour), suppleZero(minute), suppleZero(second), milliseconds]
}

export function refreshTitle(title) {
  document.title = title
}

export function isPC() {
  const u = navigator.userAgent
  const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  let flag = true
  for (let i = 0; i < Agents.length; i++) {
    if (u.indexOf(Agents[i]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

/**
 * 返回最近几天内的起止时间
 * @param num 几天前
 * @param contain 去除不包含的天数
 * @returns {string[]}
 */
export function getBeforeTime(num = 7, contain = 24 * 3600 * 1000) {
  const date = new Date()
  const YYYY = date.getFullYear()
  const MM = (date.getMonth() + 1).toString().padStart(2, '0')
  const DD = (date.getDate()).toString().padStart(2, '0')
  const YYYYMMDD = platform.ios ? `${YYYY}/${MM}/${DD}` : `${YYYY}-${MM}-${DD}`
  const max = new Date(`${YYYYMMDD} 00:00:00`).getTime() - contain
  const min = max - (num - 1) * 24 * 3600 * 1000
  const minD = new Date(min)
  const maxD = new Date(max)
  const YYYY1 = minD.getFullYear()
  const MM1 = (minD.getMonth() + 1).toString().padStart(2, '0')
  const DD1 = (minD.getDate()).toString().padStart(2, '0')
  const YYYY2 = maxD.getFullYear()
  const MM2 = (maxD.getMonth() + 1).toString().padStart(2, '0')
  const DD2 = (maxD.getDate()).toString().padStart(2, '0')
  return [`${YYYY1}-${MM1}-${DD1} 00:00:00`, `${YYYY2}-${MM2}-${DD2} 23:59:59`]
}

export function value2Name(options = [], val) {
  if (!Array.isArray(options)) {
    return ''
  }

  const index = options.findIndex(item => item.value === val)
  if (index > -1) {
    return options[index].label || options[index].name || options[index].text
  }

  return ''
}

// 自动匹配用户的第一个页面
export function getFirstRoute(arr, route) {
  const routes = arr.filter(item => !item.disabled && !item.hidden)
  let children = []

  if (!routes.length) {
    return {}
  }

  for (let i = 0; i < routes.length; i++) {
    if (routes[i].children && routes[i].children.length) {
      children = routes[i].children
    } else {
      route = { ...routes[i] }
    }

    if (children.length || Object.keys(route).length) {
      break
    }
  }

  return Object.keys(route).length ? route : getFirstRoute(children, route)
}

// 随机生成密码 8~16 位
export function getRandomPw() {
  const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const nanoid = customAlphabet(str, 8)
  let value = nanoid()

  while (!isLoginPWD(value)) {
    value = nanoid()
  }

  return value
}
