// https://nuxt.com.cn/docs/api/composables/use-fetch
export const $http = async (url, params = {}, method = 'post') => {
  await nextTick()
  const { $token } = useNuxtApp()
  return new Promise(async (resolve, reject) => {
    const config = {}
    if (method === 'get') {
      config.params = params
    } else {
      config.body = params
    }

    useFetch(url, {
      ...config,
      method,
      headers: {
        'Content-Type': 'application/json',
        token: $token.value || ''
      },
      baseURL: '',
      server: true,
      onRequest({ request, options }) {
        // Set the request headers
      },
      onRequestError({ request, options, error }) {
        // Handle the request errors
      },
      onResponse({ request, response, options }) {
        // Process the response data
        if (response._data && response._data.code !== 200 && response._data.msg) {
          ElMessage.error(response._data.msg)
        }
        return response._data
      },
      onResponseError({ request, response, options }) {
        // Handle the response errors
      }
    }).then(res => {
      resolve(res.data.value || {})
    }).catch(err => {
      resolve({})
    })
  })
}
