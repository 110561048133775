import plugin_vue3_A0OWXRrUgq from "/data/project/qianduan/visa/htmldev/web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/data/project/qianduan/visa/htmldev/web/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/data/project/qianduan/visa/htmldev/web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/data/project/qianduan/visa/htmldev/web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/data/project/qianduan/visa/htmldev/web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/data/project/qianduan/visa/htmldev/web/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/data/project/qianduan/visa/htmldev/web/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/data/project/qianduan/visa/htmldev/web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_ANExhJHvcR from "/data/project/qianduan/visa/htmldev/web/plugins/auth.js";
import utils_EeQrN1BEbD from "/data/project/qianduan/visa/htmldev/web/plugins/utils.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_UciE0i6zes,
  auth_ANExhJHvcR,
  utils_EeQrN1BEbD
]