<template>
  <NuxtLayout>
    <NuxtPage keepalive></NuxtPage>
  </NuxtLayout>
</template>
<script setup>
import { useCommonStore } from '~/store'

const commonStore = useCommonStore()

useHead({
  title: commonStore.webConfig.config_web_name
})
</script>
