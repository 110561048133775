import { default as index2EHULr9UwKMeta } from "/data/project/qianduan/visa/htmldev/web/pages/about/index.vue?macro=true";
import { default as indexza7RuknhneMeta } from "/data/project/qianduan/visa/htmldev/web/pages/contact/index.vue?macro=true";
import { default as _91countryId_93cI6GzEn8sEMeta } from "/data/project/qianduan/visa/htmldev/web/pages/country/[countryId].vue?macro=true";
import { default as index7ppdbVhcY9Meta } from "/data/project/qianduan/visa/htmldev/web/pages/country/index.vue?macro=true";
import { default as _91skuId_93YW4Wx1OUscMeta } from "/data/project/qianduan/visa/htmldev/web/pages/goods/[skuId].vue?macro=true";
import { default as indext8bI01w1TYMeta } from "/data/project/qianduan/visa/htmldev/web/pages/login/index.vue?macro=true";
import { default as indexyCC8q2tQrDMeta } from "/data/project/qianduan/visa/htmldev/web/pages/nested/menu1/menu1-1/index.vue?macro=true";
import { default as indexHYfXaa1eh1Meta } from "/data/project/qianduan/visa/htmldev/web/pages/nested/menu1/menu1-2/menu1-2-1/index.vue?macro=true";
import { default as menu1_452aPrKXOeLDaMeta } from "/data/project/qianduan/visa/htmldev/web/pages/nested/menu1/menu1-2.vue?macro=true";
import { default as menu1ClJQuxUBRVMeta } from "/data/project/qianduan/visa/htmldev/web/pages/nested/menu1.vue?macro=true";
import { default as indexEM6TA1TeE3Meta } from "/data/project/qianduan/visa/htmldev/web/pages/nested/menu2/index.vue?macro=true";
import { default as nested2p6kksb1cKMeta } from "/data/project/qianduan/visa/htmldev/web/pages/nested.vue?macro=true";
import { default as index1qBGOYUt3vMeta } from "/data/project/qianduan/visa/htmldev/web/pages/price/index.vue?macro=true";
export default [
  {
    name: index2EHULr9UwKMeta?.name ?? "about",
    path: index2EHULr9UwKMeta?.path ?? "/about",
    meta: index2EHULr9UwKMeta || {},
    alias: index2EHULr9UwKMeta?.alias || [],
    redirect: index2EHULr9UwKMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexza7RuknhneMeta?.name ?? "contact",
    path: indexza7RuknhneMeta?.path ?? "/contact",
    meta: indexza7RuknhneMeta || {},
    alias: indexza7RuknhneMeta?.alias || [],
    redirect: indexza7RuknhneMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: _91countryId_93cI6GzEn8sEMeta?.name ?? "country-countryId",
    path: _91countryId_93cI6GzEn8sEMeta?.path ?? "/country/:countryId()",
    meta: _91countryId_93cI6GzEn8sEMeta || {},
    alias: _91countryId_93cI6GzEn8sEMeta?.alias || [],
    redirect: _91countryId_93cI6GzEn8sEMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/country/[countryId].vue").then(m => m.default || m)
  },
  {
    name: index7ppdbVhcY9Meta?.name ?? "country",
    path: index7ppdbVhcY9Meta?.path ?? "/country",
    meta: index7ppdbVhcY9Meta || {},
    alias: index7ppdbVhcY9Meta?.alias || [],
    redirect: index7ppdbVhcY9Meta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: _91skuId_93YW4Wx1OUscMeta?.name ?? "goods-skuId",
    path: _91skuId_93YW4Wx1OUscMeta?.path ?? "/goods/:skuId()",
    meta: _91skuId_93YW4Wx1OUscMeta || {},
    alias: _91skuId_93YW4Wx1OUscMeta?.alias || [],
    redirect: _91skuId_93YW4Wx1OUscMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/goods/[skuId].vue").then(m => m.default || m)
  },
  {
    name: indext8bI01w1TYMeta?.name ?? "login",
    path: indext8bI01w1TYMeta?.path ?? "/login",
    meta: indext8bI01w1TYMeta || {},
    alias: indext8bI01w1TYMeta?.alias || [],
    redirect: indext8bI01w1TYMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: nested2p6kksb1cKMeta?.name ?? "nested",
    path: nested2p6kksb1cKMeta?.path ?? "/nested",
    children: [
  {
    name: menu1ClJQuxUBRVMeta?.name ?? "nested-menu1",
    path: menu1ClJQuxUBRVMeta?.path ?? "menu1",
    children: [
  {
    name: indexyCC8q2tQrDMeta?.name ?? "nested-menu1-menu1-1",
    path: indexyCC8q2tQrDMeta?.path ?? "menu1-1",
    meta: indexyCC8q2tQrDMeta || {},
    alias: indexyCC8q2tQrDMeta?.alias || [],
    redirect: indexyCC8q2tQrDMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/nested/menu1/menu1-1/index.vue").then(m => m.default || m)
  },
  {
    name: menu1_452aPrKXOeLDaMeta?.name ?? "nested-menu1-menu1-2",
    path: menu1_452aPrKXOeLDaMeta?.path ?? "menu1-2",
    children: [
  {
    name: indexHYfXaa1eh1Meta?.name ?? "nested-menu1-menu1-2-menu1-2-1",
    path: indexHYfXaa1eh1Meta?.path ?? "menu1-2-1",
    meta: indexHYfXaa1eh1Meta || {},
    alias: indexHYfXaa1eh1Meta?.alias || [],
    redirect: indexHYfXaa1eh1Meta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/nested/menu1/menu1-2/menu1-2-1/index.vue").then(m => m.default || m)
  }
],
    meta: menu1_452aPrKXOeLDaMeta || {},
    alias: menu1_452aPrKXOeLDaMeta?.alias || [],
    redirect: menu1_452aPrKXOeLDaMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/nested/menu1/menu1-2.vue").then(m => m.default || m)
  }
],
    meta: menu1ClJQuxUBRVMeta || {},
    alias: menu1ClJQuxUBRVMeta?.alias || [],
    redirect: menu1ClJQuxUBRVMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/nested/menu1.vue").then(m => m.default || m)
  },
  {
    name: indexEM6TA1TeE3Meta?.name ?? "nested-menu2",
    path: indexEM6TA1TeE3Meta?.path ?? "menu2",
    meta: indexEM6TA1TeE3Meta || {},
    alias: indexEM6TA1TeE3Meta?.alias || [],
    redirect: indexEM6TA1TeE3Meta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/nested/menu2/index.vue").then(m => m.default || m)
  }
],
    meta: nested2p6kksb1cKMeta || {},
    alias: nested2p6kksb1cKMeta?.alias || [],
    redirect: nested2p6kksb1cKMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/nested.vue").then(m => m.default || m)
  },
  {
    name: index1qBGOYUt3vMeta?.name ?? "price",
    path: index1qBGOYUt3vMeta?.path ?? "/price",
    meta: index1qBGOYUt3vMeta || {},
    alias: index1qBGOYUt3vMeta?.alias || [],
    redirect: index1qBGOYUt3vMeta?.redirect || undefined,
    component: () => import("/data/project/qianduan/visa/htmldev/web/pages/price/index.vue").then(m => m.default || m)
  }
]