import { useCommonStore } from '~/store'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const commonStore = useCommonStore()
  const webConfig = commonStore.webConfig

  if (!commonStore.token) {
    commonStore.token = useNuxtApp().$token.value
    commonStore.userPhone = useNuxtApp().$userPhone.value
  }

  if (Object.keys(webConfig).length < 1) {
    if (process.client) {
      await commonStore.fetchWebConfig()
    }
  }

  if (to.fullPath === '/') {
    return navigateTo('/country', { replace: true })
  }
})
